<template>
  <div class="main-wrap" style="padding: 10px">
    <div class="bar-wrap">
      <el-input
        v-model="search_text"
        class="search-bar"
        placeholder="請輸入活動名稱"
        :prefix-icon="Search"
        clearable
        @keyup.enter="handleSearch"
        @clear="handleSearch"
      />
      <el-button class="create-button" type="primary" @click="openDialog('storeGiftSetting', { action: 'create' })">
        新增
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getStoreGiftList"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
      class="table-basic"
      height="100%"
      @expand-change="handleRowClick"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div class="remark">
            <p v-html="'活動說明：' + props.row.description.replace(/\n/g, '<br>')"></p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="event_id" label="活動ID" width="110" />
      <el-table-column prop="event_name" label="活動名稱" :show-overflow-tooltip="true" />
      <el-table-column prop="store_list_type" label="門市" width="200">
        <template #default="scope">
          <span v-if="scope.row.store_list_type === 1">全門市</span>
          <span v-else-if="scope.row.store_list_type === 2">
            {{ getAreaText(scope.row.store_area) }}
          </span>
          <span
            v-else-if="scope.row.store_list_type === 3"
            class="download-text"
            @click="downloadFile(scope.row.store_file_url)"
          >
            指定門市（csv）
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="start_date" label="日期" width="170">
        <template #default="scope">
          <span>
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.start_date) }}~
            <br />
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.end_date) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="available_status" label="狀態" width="110" />
      <el-table-column label="功能" fixed="right" align="center" width="130">
        <template #default="scope">
          <el-dropdown class="action">
            <span class="el-dropdown-link">
              操作
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="openDialog('storeGiftSetting', { action: 'edit', init: scope.row, index: scope.$index })"
                >
                  編輯
                </el-dropdown-item>
                <el-dropdown-item @click="handleDeleteGift(scope.row)">刪除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      :page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { getCurrentInstance, defineAsyncComponent, ref, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
import { downloadFile } from '@/utils/download';
import { getAreaText } from '@/utils/define';
import { Search } from '@element-plus/icons-vue';
export default {
  name: 'store-gift',
  components: {
    StoreGiftDialog: defineAsyncComponent(() => import('@/components/discount/StoreGiftDialog.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();
    const route = useRoute();
    const router = useRouter();

    //設定參數取得資料
    const search_text = ref('');
    const listTable = ref(null);
    const currentPage = ref(1);
    const doGetStoreGiftSummary = useActions('storeGift', ['doGetStoreGiftSummary']);
    const { getStoreGiftList, getPageSize, getPageTotal } = useGetters('storeGift', [
      'getStoreGiftList',
      'getPageSize',
      'getPageTotal',
    ]);
    const doGetStoreGiftList = useActions('storeGift', ['doGetStoreGiftList']);

    const refreshList = async (action) => {
      if (action === 'create') {
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
        search_text.value = '';
      } else if (action === 'search') {
        currentPage.value = 1;
        const query = { page: currentPage.value };
        router.push({ query });
      }
      if (action !== 'edit' && action !== 'page') await doGetStoreGiftSummary(search_text.value);
      if (currentPage.value > Math.ceil(getPageTotal.value / getPageSize.value))
        currentPage.value = Math.max(Math.ceil(getPageTotal.value / getPageSize.value), 1);
      await doGetStoreGiftList({ event_name: search_text.value, start: (currentPage.value - 1) * getPageSize.value });
      closeDialog();
    };

    //初始化
    const query = { page: 1 };
    router.push({ query, replace: true }).then(() => {
      currentPage.value = Number(route.query.page);
      refreshList('init');
    });

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };

    //搜尋
    const handleSearch = () => {
      refreshList('search');
    };

    //更新list
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          refreshList('page');
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //刪除訊息
    const doDeleteStoreGift = useActions('storeGift', ['doDeleteStoreGift']);
    const handleDeleteGift = (item) => {
      proxy
        .$confirm(`確定要刪除－${item.event_name}？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeleteStoreGift(item.event_id);
          refreshList('delete');
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        });
    };

    //展開收合row
    const handleRowClick = (row, expandedRows) => {
      if (expandedRows.includes(row)) {
        expandedRows.forEach((e) => {
          if (e !== row) listTable.value.toggleRowExpansion(e, false);
        });
      }
    };

    //離開清空vuex
    const RESET = useMutations('storeGift', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/discount/gift')) RESET();
      }
    );

    return {
      Search,
      getAreaText,
      formatDateTime,
      downloadFile,
      dialogTemplate,
      openDialog,
      search_text,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      getStoreGiftList,
      handleCurrentChange,
      handleSearch,
      refreshList,
      handleDeleteGift,
      handleRowClick,
    };
  },
};
</script>

<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$searchbar-height: 70px;
$pagination-height: 50px;
.main-wrap {
  padding-top: $searchbar-height !important;
  padding-bottom: $pagination-height !important;
}

.bar-wrap {
  position: absolute;
  display: flex;
  top: calc($searchbar-height / 2);
  right: $padding-unit;
  transform: translateY(-50%);
}
.create-button {
  width: 110px;
  margin-left: 20px;
}
.search-bar {
  width: 200px;
}
.table-basic {
  width: 100%;
  border-top: 1px solid $el-border-color-lighter;
}
.action {
  cursor: pointer;
  width: 110px;
  @include flex-center-center;
}
.el-dropdown-link {
  color: $color-primary;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
.remark {
  padding: 10px 60px;
  color: $color-text-light;
  font-size: 1.5rem;
}
</style>
